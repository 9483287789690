wired-card {
    display: block;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
}

.Calendar__svg-container {
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	width: 100%;
}
