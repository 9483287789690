.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.App__calendar {
  text-align: center;
  margin: auto;
  width: 80%;
  height: 100%;
}

